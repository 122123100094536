<template>
    <div class="login">
        <div class="showToast" v-if="showToast">
            <div class="ban flex">
                <div class="banIco">
                    <img src="../../assets/images/loginPage/Vector.png" alt="" />
                </div>
                <p>当前网络不可用，请检查你的网络设置</p>
            </div>
        </div>
        <div class="left-content">
            <div class="login_logo"></div>
            <div class="title">
                <p>Hi,欢迎登录!</p>
                <p>智慧餐饮信息化平台v2.1</p>
                <p>Make the world a better place</p>
            </div>
            <a-spin :spinning="spinning">
                <a-form :form="loginData" @submit="login" class="input-content">
                    <div class="mobile">
                        <a-input
                            placeholder="输入手机号码"
                            v-model="loginData.mobile"
                            :maxLength="11"
                            @blur="mobileInput"
                            @pressEnter="mobileEnter"
                        >
                            <b class="iconTel" slot="prefix">
                                <img src="@/assets/images/login_mobile.png" />
                            </b>
                        </a-input>
                    </div>
                    <div v-if="isShow">
                        <p class="redText">输入的手机号码不合法</p>
                    </div>
                    <div class="pwd">
                        <div class="password">
                            <a-input-password
                                id="pwd"
                                placeholder="输入密码"
                                v-model="loginData.verifyCode"
                                @blur="pwdInput"
                                @pressEnter="login"
                            >
                                <b class="iconPsd" slot="prefix">
                                    <img src="@/assets/images/psd.png" />
                                </b>
                            </a-input-password>
                        </div>
                        <div v-if="isShowPwd">
                            <p class="redText">输入的密码不合法</p>
                        </div>
                        <div v-if="isShowMsg">
                            <p class="redText">用户名密码错误</p>
                        </div>
                    </div>
                    <fs-button type="primary" @click="login" @keyup.enter="login">
                        登录
                    </fs-button>
                </a-form>
            </a-spin>
        </div>
        <div class="right-content"></div>
    </div>
</template>

<script>
import FsButton from '@/components/FsButton/FsButton.vue'
import { mapActions, mapMutations } from 'vuex'
import api from '@/mock/services/login.js'
import Cookies from 'js-cookie'

export default {
    name: 'login',
    data() {
        return {
            loginData: { mobile: '', verifyCode: '' },
            count: 60,
            codeText: '获取验证码',
            isDisabled: false,
            spinning: false,
            isShow: false,
            isShowPwd: false,
            isShowMsg: false,
            showToast: false
        }
    },
    components: {
        FsButton
    },
    mounted() {
        this.checkCookie()
    },
    methods: {
        ...mapActions('user', {
            getUserPermission: 'GET_USER_PERMISSION'
        }),
        ...mapMutations(['updateUserRights', 'updateCanteenRightSwitchList']),
        checkCookie() {
            let account = Cookies.get('boxi-cy-account')
            let pwd = Cookies.get('boxi-cy-pwd')
            this.loginData.mobile = account === undefined ? this.loginData.mobile : account
            this.loginData.verifyCode = pwd === undefined ? this.loginData.verifyCode : pwd
        },
        ...mapMutations(['clearAllDate', 'updateSelectKey', 'updateOpenKey']),
        async getCode() {
            if (this.isDisabled) {
                this.$alert.error('请稍后再试')
                return
            }
            this.spinning = true
            let regex = /^1[0-9]\d{4,9}$/
            if (this.loginData.mobile.trim() && !regex.test(this.loginData.mobile.trim())) {
                this.isShow = true
            } else {
                this.isShow = false
            }
            const res = await api.getCode('SYSTEM_TYPE$CM_PC', this.loginData.mobile.trim())
            if (res) {
                this.spinning = false
            }
            if (res.success == true) {
                this.$alert.info(res.msg)
                // todo开始倒计时
                let count = this.count
                var countdown = setInterval(() => {
                    if (count == 0) {
                        this.codeText = '重新发送'
                        this.isDisabled = false
                        // 取消定时器
                        clearInterval(countdown)
                    } else {
                        this.codeText = '重新发送(' + count-- + 's)'
                        this.isDisabled = true
                    }
                }, 1000)
            } else {
                this.$alert.error(res.msg)
            }
        },
        async login() {
            Cookies.set('boxi-cy-account', this.loginData.mobile, { expires: 30 })
            Cookies.set('boxi-cy-pwd', this.loginData.verifyCode, { expires: 30 })
            localStorage.clear()
            sessionStorage.clear()
            this.clearAllDate()
            let params = {
                // loginTypeEnum: "VERIFYCODE",
                loginTypeEnum: 'PASSWORD',
                system: 'SYSTEM_TYPE$CM_PC',
                mobile: this.loginData.mobile,
                // verifyCode: this.loginData.verifyCode
                password: this.loginData.verifyCode
            }
            const loginRes = await api.userLogin(params);
            // console.log(loginRes);
            if (loginRes.success == true) {
                sessionStorage.setItem('USERINFO', JSON.stringify(loginRes.results))
                this.updateUserRights(loginRes.results.rightNames)
                this.updateCanteenRightSwitchList(loginRes.results.canteenRightSwitchList)
                this.getUserPermission()
                this.updateSelectKey('home')
                this.updateOpenKey([])
                this.$router.push('/home')
            } else {
                this.$alert.error(loginRes.msg)
                if (loginRes.msg === '用户名密码错误') {
                    this.isShow = false
                    this.isShowPwd = false
                    this.isShowMsg = true
                }
            }
        },
        mobileInput() {
            let regex = /^[1][0-9]{10}$/ //
            if (this.loginData.mobile.trim() && !regex.test(this.loginData.mobile.trim())) {
                this.isShow = true
            } else {
                this.isShow = false
            }
        },
        mobileEnter() {
            let pwd = document.getElementById('pwd')
            pwd.focus()
        },
        pwdInput() {
            let regCode = /^(?=.*[0-9]{1})[a-zA-Z0-9]{6,10}$/ //密码校验 数字6-10，数字+字母6-10
            if (
                this.loginData.verifyCode.trim() &&
                !regCode.test(this.loginData.verifyCode.trim())
            ) {
                this.isShowPwd = true
            } else {
                this.isShowPwd = false
            }
        }
    }
}
</script>

<style lang="less" scoped>
.flex {
    display: flex;
}
input {
    height: 40px;
}
button {
    height: 40px !important;
}
.ant-btn.active {
    background: #2b9363;
    border-color: #2b9363;
    color: #fff;
}
.login {
    height: 100vh;
    display: flex;
    background: url('../../assets/images/loginPage/Bigposter.png') no-repeat;
    background-size: 60% auto;
    background-position: top right;
    position: relative;
    .showToast {
        width: 100%;
        height: .px2vh(50) [ @vh];
        line-height: .px2vh(50) [ @vh];
        text-align: center;
        position: absolute;
        top: 0;
        background-color: rgba(255, 88, 65, 0.1);
        color: black;
        font-size: .px2vw(20) [ @vw];
        text-align: center;
        .ban {
            text-align: center;
            line-height: .px2vh(50) [ @vh];
            .banIco {
                width: .px2vh(20) [ @vh];
                height: .px2vh(20) [ @vh];
                img {
                    width: .px2vh(20) [ @vh];
                    height: .px2vh(20) [ @vh];
                }
                p {
                    font-size: .px2vh(20) [ @vh];
                }
            }
        }
    }
    .left-content {
        width: .px2vw(818) [ @vw];
        .login_logo {
            width: 200px;
            height: 120px;
            margin: 21px 0 0 46px;
            background: url('../../assets/images/login/logo.png') no-repeat;
            background-size: 100% auto;
        }
        .title {
            margin: .px2vh(120) [ @vh] 0 .px2vh(80) [ @vh] .px2vw(130) [ @vw];
            p:nth-of-type(1) {
                height: 44px;
                line-height: 44px;
                font-size: 32px;
                font-family: 'puhuiti-medium';
                font-weight: 500;
                color: #000;
            }
            p:nth-of-type(2) {
                margin-top: .px2vw(-3) [ @vw];
                height: 27px;
                line-height: 27px;
                font-size: 20px;
                font-family: 'puhuiti';
                font-weight: 300;
                color: #979797;
            }
            p:nth-of-type(3) {
                margin-top: .px2vw(10) [ @vw];
                color: #c5c5c5;
                font-size: 12px;
            }
        }
        .mobile {
            .ant-input:hover {
                border: rgb(255, 0, 0);
            }
        }
        .iconTel {
            img {
                width: 22 / @vw;
                height: 33 / @vw;
            }
        }
        .iconPsd {
            img {
                width: 23 / @vw;
                height: 27 / @vw;
            }
        }
        .input-content {
            width: 550 / @vw;
            margin: auto;
            margin-top: 72px;
            .icon {
                width: 16px;
            }
            .pwd {
                margin-bottom: 50px;
                height: 36px;
                .password {
                    display: flex;
                    margin: 10px 0 0 0;
                }
            }
            button {
                width: 550 / @vw;
                background: rgb(65, 179, 73);
            }
            .redText {
                margin-top: .px2vh(6) [ @vh];
                color: red;
            }
        }
    }
    .ant-btn {
        height: 260 / @vh;
    }
    .ant-btn-primary {
        height: 260 / @vh;
    }
    button {
        height: 56 / @vh !important;
        font-size: .px2vh(24) [ @vh];
    }
    .mobile,
    .password {
        .ant-input-affix-wrapper {
            /deep/ .ant-input {
                height: 60 / @vw;
                padding: 14 / @vw 20 / @vw 14 / @vw 60 / @vw;
                border-radius: 10 / @vw;
                font-size: 20 / @vw;
                border-width: 2 / @vw;
            }
            b {
                display: block;
                img {
                    display: inline-block;
                }
                &:after {
                    content: '';
                    display: inline-block;
                    width: 13 / @vw;
                    height: 20 / @vw;
                    border-right: 1 / @vw solid #e5e5e5;
                    top: 4px;
                    position: relative;
                }
            }
        }
    }
    .right-content {
        width: .px2vw(1102) [ @vw];
        height: inherit;
        // background: url("../../assets/images/login/login_bg.png") no-repeat;
        // background-size: 90% auto;
        // background-position: right 120px;
    }

    /**媒体查询做适配 小屏*/
    // @media screen and (max-width: 900px) {
    //   .left-content {
    //     width: 100%;
    //   }
    //   .right-content {
    //     display: none;
    //   }
    // }
    /*大屏*/
    // @media screen and (min-width: 1200px) {
    //   .left-content {
    //     width: 100%;
    //   }
    //   .right-content {
    //     width: 902px;
    //   }
    // }
}
</style>
